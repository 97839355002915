import React from 'react';
import '../../scss/Resume.scss'


const Resume = () => {

    return (
        <div className="Resume">
            <iframe src="files/jon_bent_resume.pdf" title="resume"/>
        </div>
    );
};

export default Resume;